<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Login card -->

          <div class="auth-wrap ng-tns-c394-2">
            <div class="auth-wrap-left --fd-column ng-tns-c394-2">
              <!-- <h3 class="auth-title ng-tns-c394-2">{{site_settings.name }}:</h3> -->
              <h3 class="auth-title ng-tns-c394-2">
                Delegate Your Tasks to Professionals
              </h3>

              <div class="left-panel ng-tns-c394-2">
                <auth-banner class="ng-tns-c394-2" _nghost-jfn-c393=""><div _ngcontent-jfn-c393="" class="--d-flex --fd-column --h-100per">
                  <div _ngcontent-jfn-c393="" class="auth-banner-list-wrapper --pt-24">
                    <ul _ngcontent-jfn-c393="" class="auth-banner-list">
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="like" class="anticon auth-banner-list-item-icon anticon-like"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="like" aria-hidden="true"><path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 00-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 00471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0142.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">1. Original papers</h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            All papers ordered with us are written from scratch by a team of professionals in the specific chosen subject. We guarantee plagiarism free papers for each school task you order with us
                          </p>
                        </div>
                      </li>
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="credit-card" class="anticon auth-banner-list-item-icon anticon-credit-card"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="credit-card" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-792 72h752v120H136V232zm752 560H136V440h752v352zm-237-64h165c4.4 0 8-3.6 8-8v-72c0-4.4-3.6-8-8-8H651c-4.4 0-8 3.6-8 8v72c0 4.4 3.6 8 8 8z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">2. Best grades </h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            All our writers use physical or online libraries and journals to conduct adequate research before writing any assignment. Please ensure that you upload all relevant instructions and rubrics.
                          </p>
                        </div>
                      </li>
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="safety-certificate" class="anticon auth-banner-list-item-icon anticon-safety-certificate"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="safety-certificate" aria-hidden="true"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5 214 654.3V226.7l298-101.6 298 101.6v427.6zm-405.8-201c-3-4.1-7.8-6.6-13-6.6H336c-6.5 0-10.3 7.4-6.5 12.7l126.4 174a16.1 16.1 0 0026 0l212.6-292.7c3.8-5.3 0-12.7-6.5-12.7h-55.2c-5.1 0-10 2.5-13 6.6L468.9 542.4l-64.7-89.1z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">3. Confidentiality</h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            
                            We understand the nature of our business and have, therefore,  <span _ngcontent-jfn-c393="" class="--fw-600">upgraded our systems to the highest security level {L3S}</span> or Level 3 security for systems. With all systems not being limited or subject to  any US laws or jurisdiction.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                </auth-banner>
              </div>
            </div>

            <div class="right-panel ng-tns-c394-2">
              <!-- <h3 class="auth-title ng-tns-c394-2 font-weight-bold ">{{site_settings.name }}:</h3>  -->
              <div class="text-center mb-3"><h5 class="mb-0 bold-title ">Pristine Writers</h5>
                <div class="sub-title-hint"> Hi, {{ name }} <br />
                    Reset your password</div>
              </div>
              <router-outlet class="stretch-outlet ng-tns-c394-2 ng-trigger ng-trigger-EaseInOutAnimation"></router-outlet><auth-login-component class="auth-container ng-star-inserted" _nghost-jfn-c395=""><div _ngcontent-jfn-c395="" class="--d-flex --fd-column --jc-center --flex-1">
                <div _ngcontent-jfn-c395="">
                  <form  @submit.prevent="resetPassword" _ngcontent-jfn-c395="" novalidate="" nz-form="" nzlayout="vertical" class="--mb-16 new-auth-form ant-form ant-form-vertical ng-pristine ng-invalid ng-star-inserted ng-touched">
                    
                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-4 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-4"><div class="ant-form-item-control-input-content ng-tns-c149-4">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-4">Password</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-4 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="lock" nztheme="outline" class="anticon anticon-lock ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="lock" aria-hidden="true"><path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path></svg></i>
                          </span>
                            <input required v-model="resetForm.password" _ngcontent-jfn-c395="" nz-input="" autocomplete="on" placeholder="Password" class="ant-input ant-input-lg ng-untouched ng-pristine ng-invalid ng-star-inserted" :type="password_type">
                            
                            <span v-if="password_type == 'text'" @click="password_type = 'password' " type="button"  nz-input-group-slot=""  class="ant-input-suffix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" class="anticon --cursor-pointer anticon-eye-invisible ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye-invisible" aria-hidden="true"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path></svg></i>
                           </span>

                           <span v-if="password_type == 'password'" nz-input-group-slot="" @click="password_type = 'text' " type="button" class="ant-input-suffix ng-star-inserted">
                            <i nz-icon="" class="anticon --cursor-pointer ng-star-inserted anticon-eye"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye" aria-hidden="true"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></i>
                           </span>
                          </nz-input-group>
                          
                          
                        
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>

                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-4 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-4"><div class="ant-form-item-control-input-content ng-tns-c149-4">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-4">Password Confirmation</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-4 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="lock" nztheme="outline" class="anticon anticon-lock ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="lock" aria-hidden="true"><path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path></svg></i>
                          </span>
                            <input required v-model="resetForm.password_confirmation" _ngcontent-jfn-c395="" nz-input="" autocomplete="on" placeholder="Password" class="ant-input ant-input-lg ng-untouched ng-pristine ng-invalid ng-star-inserted" :type="password_type">
                            
                            <span v-if="password_type == 'text'" @click="password_type = 'password' " type="button"  nz-input-group-slot=""  class="ant-input-suffix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" class="anticon --cursor-pointer anticon-eye-invisible ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye-invisible" aria-hidden="true"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path></svg></i>
                           </span>

                           <span v-if="password_type == 'password'" nz-input-group-slot="" @click="password_type = 'text' " type="button" class="ant-input-suffix ng-star-inserted">
                            <i nz-icon="" class="anticon --cursor-pointer ng-star-inserted anticon-eye"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye" aria-hidden="true"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></i>
                           </span>
                          </nz-input-group>
                          
                          
                        
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>


                    <div _ngcontent-jfn-c395="" class="--d-flex --ai-center --mb-16">
                      <router-link :to="{ name: 'Login' }" _ngcontent-jfn-c395="" class="nz-link" >Remember password?</router-link>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <div class="g-recaptcha" data-sitekey="your_site_key"></div>
                    </div>

                    <button type="submit"
                    :disabled="resetForm.busy"
                     _ngcontent-jfn-c395=""   nz-button="" nzblock="" class="ant-btn ant-btn-auth  text-white  --bdrs-24 ant-btn-lg ant-btn-block"><span class="ng-star-inserted">
                      {{ resetForm.busy ? "Loading.." : "Reset Password"}}
                      </span><i _ngcontent-jfn-c395="" nz-icon="" nztype="arrow-right" class="anticon ant-btn-auth-arrow-rt anticon-arrow-right">
                      </i><span class="ng-star-inserted">
                    </span></button>
                  </form>
                </div>
              </div>
              </auth-login-component>
            </div>
          </div>

          <!-- <form
            class="login-form"
            ref="login-form"
            @submit.prevent="resetPassword"
          >
            <div class="card mb-0 shadow-lg rounded">
              <div class="card-body">
                <div class="text-center mb-3">
                  <i
                    class="
                      icon-reading icon-2x
                      text-slate-300
                      border-slate-300 border-3
                      rounded-round
                      p-3
                      mb-3
                      mt-1
                    "
                  ></i>
                  <h5 class="mb-0">Password reset</h5>
                </div>

                <div>
                  <h4 class="text-capitalize">
                    Hi, {{ name }} <br />
                    Reset your password
                  </h4>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="password"
                    v-model="resetForm.password"
                    name="password"
                    required
                    class="form-control"
                    placeholder="New Password"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="password"
                    v-model="resetForm.password_confirmation"
                    name="password_confirmation"
                    required
                    class="form-control"
                    placeholder="Password confirmation"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                </div>


                <div class="form-group d-flex align-items-center">
                  <div class="g-recaptcha" data-sitekey="your_site_key"></div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    :disabled="resetForm.busy"
                    class="btn bg-primary-400 btn-block"
                  >
                    {{ resetForm.busy ? "Loading.." : "Reset Password"
                    }}<i class="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <div class="form-group">
                  <router-link
                    :to="{ name: 'Login' }"
                    class="btn btn-light btn-block"
                    >Login</router-link
                  >
                </div>

                <span class="form-text text-center text-muted"
                  >By continuing, you're confirming that you've read our
                  <a href="#">Terms &amp; Conditions</a> and
                  <a href="#">Cookie Policy</a></span
                >
              </div>
            </div>
          </form> -->
          <!-- /login card -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ResetPassword",
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      resetForm: new this.$Form({
        password: "",
        password_confirmation: "",
        token: "",
      }),
      name: "",
    };
  },
  methods: {
    ...mapActions("auth", ["doResetPassword", "doGetAuthUser"]),
    encodePassword(password) {
      return window.btoa(unescape(encodeURIComponent(password)));
    },
    resetPassword() {
      this.resetForm.password = this.encodePassword(this.resetForm.password);
      this.resetForm.password_confirmation = this.encodePassword(
        this.resetForm.password_confirmation
      );
      this.doResetPassword(this.resetForm)
        .then((res) => {
          

          this.$swal({
                icon: 'success',
                title: 'Success',
                text:  res.data
              ? res.data.Message
              : "Password reset successiful, login to your account.",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
          
        })
        .catch((err) => {
          

          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
        });
    },
  },
  mounted() {
    let token = this.$route.query.token;
    let name = this.$route.query.name;
    if (token && name) {
      this.resetForm.token = token;
      this.name = name;
    } else {
      document.location.href = `/login/`;
    }
    this.$parent.$emit("loader:hide");
  },
};
</script>
